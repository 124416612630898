import React from "react";
import { Chip, Avatar } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

const topicStyle = {
  margin: "0.5rem",
  padding: "0.4375rem 0.75rem",
};

const TopicsByCategory = ({ topics, handleSelectTopic }) => {
  return topics.map((topic, index) => {
    const topicClasses = topic.selected ? "topic-selected" : "";
    let addedStyle = {};
    if (topic.selected) {
      addedStyle = {
        border: "1px solid #f50057",
        background: "rgb(173, 147, 14);",
      };
    }
    let inprogress = "";
    if (topic.qtyDone < topic.qtyTodo / 2) {
      inprogress = "inprogress";
    } else {
      inprogress = "done";
    }
    return (
      <Chip
        style={{
          ...topicStyle,
          ...addedStyle,
        }}
        avatar={
          <Avatar
            style={{ width: "20px", height: "20px" }}
            alt={`${topic.topic}`}
            src={`/assets/topic-img/${topic.topic}.png`}
          />
        }
        key={topic.topic}
        label={`${topic.name || topic.topic}`}
        className={`${topicClasses} ${inprogress}`}
        onClick={handleSelectTopic(topic)}
        deleteIcon={<DoneIcon />}
      />
    );
  });
};

export default TopicsByCategory;
