import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SchoolIcon from "@material-ui/icons/School";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import Redeem from "@material-ui/icons/Redeem";
import LiveHelp from "@material-ui/icons/LiveHelp";
import CardStudent from "./CardStudent";

function SectionStudents() {
  const [country, setCountry] = useState("France");
  const history = useHistory();

  return (
    <section className="bg-gradient-to-b from-white to-gray-100">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h1
              className="h1 mb-4 text-3xl	font-bold leading-tight font-montserrat"
              data-aos="zoom-y-out"
            >
              Entretien.tech est une plateforme unique en {country} pour réussir
              votre prochain objectif
            </h1>
            <p
              className="text-xl text-gray-600"
              data-aos="zoom-y-out"
              data-aos-delay="150"
            >
              Plus de 200 utilisateurs nous font confiance, notre objectif est
              que vous soyiez plus confiant et mieux préparés
            </p>
          </div>

          <div className="container of studen tables">
            <div className="max-w-sm md:max-w-2xl xl:max-w-none mx-auto grid gap-8 md:grid-cols-2 xl:grid-cols-4 xl:gap-6 items-start">
              <CardStudent
                onClick={() => history.push("/questions")}
                number="2250+"
                icon={SchoolIcon}
                subtitle="questions"
                description="Soyez préparés, choisissez parmi plus de 2200+ questions. "
                hasAvatar={true}
              />
              <CardStudent
                onClick={() => history.push("/problems")}
                number="180"
                icon={LiveHelp}
                subtitle="algorithmes"
                description="Les questions d'algorithmes de google, facebook."
                hasAvatar
              />
              <CardStudent
                number="200+"
                icon={LocalLibraryIcon}
                subtitle="Utilisateurs"
                description="Fin 2020 entretien.tech comptait moins de 10 utilisateurs."
                hasAvatar
              />
              <CardStudent
                onClick={() => history.push("/questions")}
                number="400"
                icon={Redeem}
                subtitle="questions gratuites"
                description="Pour aider les débutants, des questions gratuites pour en libre accès."
                hasAvatar={true}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionStudents;
