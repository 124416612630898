import React, { Component, Fragment } from "react";
import "./PriceLabel.style.css";

const oldPriceStyle = {
  position: "absolute",
  right: 0,
  fontSize: "10px",
  textDecoration: "line-through",
};
export class PriceLabel extends Component {
  render() {
    const {
      price = 0,
      title,
      selected,
      duration,
      moreClasses,
      product,
      validCoupon,
    } = this.props;
    let className = "radio-price " + moreClasses;
    if (product === selected) {
      className = "radio-price-selected " + moreClasses;
    }

    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: "8px" }}>{title}</span>
          <div
            className={className}
            style={{
              padding: "0rem 0.75rem",
              borderRadius: "0.2em",
              textShadow:
                "rgba(0, 0, 0, 0.4) 0px 1px 3px, rgba(0, 0, 0, 0.075) 0px 0px 30px",
              margin: "0",
              fontWeight: "700",
              position: "relative",
            }}
          >
            <span>
              <sup style={{ fontSize: "80%" }}>€</sup>
              {validCoupon && validCoupon.name
                ? Math.trunc(price * (1 - validCoupon.pourcent / 100)) + 0.99
                : price}
              <span style={{ fontSize: "40%" }}> EUR</span>
            </span>
            {validCoupon && validCoupon.name && (
              <span style={oldPriceStyle}>{price}</span>
            )}
          </div>
          <span style={{ fontSize: "10px" }}>{duration}</span>
        </div>
      </Fragment>
    );
  }
}

export default PriceLabel;
