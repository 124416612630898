import React from "react";
import styled from "styled-components";
// Component , { Fragment }
import { Link as RouterLink } from "react-router-dom";
import PremiumIcon from "app/images/premium.svg";
// import { signInWithGoogle } from "../firebase/firebase.utils";
import "./Navbar.style.css";

import { AppBar, Toolbar, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";

import Menu from "./Menu";
import Unlock from "features/unlock/Unlock";
import LoginModal from "features/loginModal/ContainerLoginModal";
import Logo from "app/images/Entretien.png";

const divStyle = {
  height: "40px",
  width: "40px",
};

const appBarStyle = {
  background: "white",
};

const container = {
  width: "1170px",
  margin: "auto",
  maxWidth: "90vw",
};

const userAvatar = {
  width: "1.875rem",
  height: "1.875rem",
  borderRadius: "50%",
  marginLeft: "1rem",
};

// const forbidden = {
//   width: "1.5rem",
//   height: "1.5rem",
//   marginRight: "8px",
// };

// #E89A3A adobe color

const Brand = (props) => (
  <Typography {...props} variant="h6" style={{ flexGrow: 1, display: "block" }}>
    <Link component={RouterLink} to="/" style={{ color: "black" }}>
      <div className="logo-text">
        <img src={Logo} style={divStyle} alt="icon" />
      </div>
    </Link>
  </Typography>
);

const StyledBrand = styled(Brand)`
  a {
    .logo-text {
      display: flex;
      align-items: center;
    }
    &:hover {
      text-decoration: none;
    }
  }
`;

const Navbar = (props) => {
  const { currentUser } = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(false);
  }, [currentUser]);

  // https://github.com/omerevrencomtr/react_example/blob/569a2a5c277d58575f58667acf5b77ee1850d868/src/app/pages/home/google-material/navigation/LinksExamplesPage.js
  return (
    <div>
      <AppBar position="fixed" style={appBarStyle}>
        <Toolbar style={container}>
          <StyledBrand />
          {currentUser && currentUser.isPremium ? (
            <img style={{ width: "28px", height: "28px" }} src={PremiumIcon} />
          ) : (
            <>
              <Unlock />
            </>
          )}
          {currentUser ? (
            <>
              <img src={currentUser.photoURL} alt="icon" style={userAvatar} />
              <Menu />
            </>
          ) : (
            <>
              <Button
                // onClick={signInWithGoogle}
                onClick={handleOpen}
                id="signin"
                // color="primary"
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "#615104",
                  color: "white",
                  textShadow:
                    "0 1px 3px rgba(0,0,0,.4), 0 0 30px rgba(0,0,0,.075)",
                }}
                variant="contained"
              >
                Connexion
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <LoginModal isOpen={open} handleClose={handleClose} />
    </div>
  );
};

export default Navbar;
