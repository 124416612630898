import React, { useEffect, useState } from "react";
import { Card, CardContent, Tab, Tabs } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import { useSelector, useDispatch } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import ClipLoader from "react-spinners/ClipLoader";
import "./topics.styles.scss";

import {
  topicsListSelector,
  loadingSelector,
  getTopicsList,
} from "app/redux/slices/questionSlice";

// fade

import { makeStyles } from "@material-ui/core/styles";
import { Search as SearchIcon } from "@material-ui/icons";
import { useStylesFunc } from "./TopicStyles";
import QuestionsWithSelectedTopic from "./QuestionsWithSelectedTopic";
import TopicsByCategory from "./TopicsByCategory";
import TabPanel from "./TabPanel";

const useStyles = makeStyles(useStylesFunc);

const SearchInput = () => {
  const searchclasses = useStyles();

  return (
    <div className={searchclasses.search}>
      <div className={searchclasses.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Filtrer par langage..."
        classes={{
          root: searchclasses.inputRoot,
          input: searchclasses.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
};

const TopicInProgress = () => {
  // moment().add(7, "days").format();
  var date = new Date();

  date.setDate(date.getDate() + 50);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  date.toLocaleDateString("fr", options);

  // return (
  //   <Alert severity="warning">
  //     <AlertTitle>Topic en cours</AlertTitle>
  //     Ce topic est prévu pour le{" "}
  //     <strong>{date.toLocaleDateString("fr", options)}</strong>
  //   </Alert>
  // );
  return (
    <Alert severity="error" style={{ marginTop: "2rem" }}>
      Vous n'avez pas accès à cette ressource
    </Alert>
  );
};

const Topics = ({ currentUser, analyzer }) => {
  const dispatch = useDispatch();
  const topicsList = useSelector(topicsListSelector);
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState({});
  // const [loading, setLoading] = useState(false);
  const loading = useSelector(loadingSelector);
  const searchclasses = useStyles();

  useEffect(() => {
    dispatch(getTopicsList());
  }, [dispatch]);

  const handleSelectTopic = (topicClicked) => () => {
    const newTopics = topics.map((topic) => {
      if (topic === topicClicked) {
        return { ...topic, selected: true };
      } else return { ...topic, selected: false };
    });

    setTopics(newTopics);
    setSelectedTopic(topicClicked);
  };

  const [valueTab, setValueTab] = useState("one");

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const topicsCodingDataStrucutres = topicsList.filter((el) => {
    const isCoding = el.categories && el.categories[0] == "coding";
    return isCoding;
  });

  const topicsSystemArchitecture = topicsList.filter((el) => {
    const isCoding = el.categories && el.categories[0] == "system design";
    return isCoding;
  });
  const topicsFullstack = topicsList.filter((el) => {
    return !el.categories || el.categories[0] == "full stack";
  });

  return (
    <>
      <Card
        id="topics"
        style={{ borderRadius: "24px" }}
        className={"cardTopic"}
      >
        {/* <SearchInput /> */}
        <CardContent>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab value="one" label="Full-stack, web & mobile" />
            <Tab value="two" label="Coding / structures de données" />
            <Tab value="three" label="Système / architecture" />
          </Tabs>
          <TabPanel value={valueTab} index="one">
            {loading && (
              <p className={searchclasses.loading}>
                <ClipLoader />
              </p>
            )}
            <TopicsByCategory
              handleSelectTopic={handleSelectTopic}
              topics={topicsFullstack}
            />
          </TabPanel>
          <TabPanel value={valueTab} index="two">
            <TopicsByCategory
              handleSelectTopic={handleSelectTopic}
              topics={topicsCodingDataStrucutres}
            />
          </TabPanel>
          <TabPanel value={valueTab} index="three">
            <TopicsByCategory
              handleSelectTopic={handleSelectTopic}
              topics={topicsSystemArchitecture}
            />
          </TabPanel>
        </CardContent>
      </Card>
      {selectedTopic.topic &&
        ((selectedTopic.qty &&
          selectedTopic.qtyDone > selectedTopic.qtyTodo / 2) ||
          !selectedTopic.qtyTodo) && (
          <>
            <h1 className="text-3xl">
              Toutes les questions sur <span>{selectedTopic.topic}</span>
            </h1>
            <QuestionsWithSelectedTopic
              currentUser={currentUser}
              selectedTopic={selectedTopic}
              analyzer={analyzer}
            />
          </>
        )}

      {selectedTopic.qtyDone < selectedTopic.qtyTodo / 2 && <TopicInProgress />}
    </>
  );
};

export default Topics;
