import React from "react";
// , { useState }

import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";
// import Modal from "@material-ui/core/Modal";
import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
// import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Close from "@material-ui/icons/Close";
import "./ContainerLoginModal.css";
import { Facebook as FacebookIcon } from "@material-ui/icons";
// import FaceIcon from "@material-ui/icons/Face";
import { signInWithGoogle, signInWithFacebook } from "firebase/firebase.utils";

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

// https://material-ui.com/guides/composition/

const useStyles = makeStyles((theme) => ({
  closeStyle: {
    // width: "24px",
    // height: "24px",
    position: "absolute",
    right: "8px",
    top: "8px",
    cursor: "pointer",
  },
  windowStyle: {
    padding: "40px 32px 32px",
    width: "400px",
  },
  titleStyle: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
    color: "rgb(32, 38, 46)",
    marginBottom: "0",
  },
  conditionStyle: {
    fontSize: "12px",
    lineHeight: "18px",
  },
}));

// const closeStyle = {
//   // width: "24px",
//   // height: "24px",
//   position: "absolute",
//   right: "8px",
//   top: "8px",
//   cursor: "pointer",
// };

// const windowStyle = {
//   padding: "40px 32px 32px",
//   width: "400px",
// };

// const loginSignUpStyle = {
//   width: "400px",
// };

// const titleStyle = {
//   fontWeight: "bold",
//   fontSize: "20px",
//   lineHeight: "28px",
//   textAlign: "center",
//   color: "rgb(32, 38, 46)",
//   marginBottom: "0",
// };
// const conditionStyle = {
//   fontSize: "12px",
//   lineHeight: "18px",
// };

// const googleBtn = {
//   backgroundColor: "#fff",
//   color: "#dc4e41",
//   width: "100%",
// };

const SignUpModal = (props) => (
  <div className="signup-modal">
    <TextField className="input-signup" label="Adresse" variant="outlined" />
    <TextField
      className="input-signup"
      label="Mot de passe"
      variant="outlined"
    />
    <Box alignItems="center" display="flex" flexDirection="column">
      <Button variant="contained" color="primary" disableElevation>
        Se connecter
      </Button>
    </Box>
    <p className="forgotten-password">Mot de passe oublié ?</p>
  </div>
);

const LoginModal = ({ isOpen, handleClose, fullScreen, classes }) => {
  // const [modalVar, setModalVar] = useState("LOGIN");

  // const { isOpen, handleClose } = props;
  // const { fullScreen, classes = {} } = props;

  // const modalContent = <SignUpModal />;
  const localClasses = useStyles();
  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={false}
    >
      <div
        //  style={windowStyle}
        className={localClasses.windowStyle}
        id="login-modal"
      >
        <IconButton
          key="close"
          aria-label="Close"
          // style={closeStyle}
          className={localClasses.closeStyle}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
        <DialogTitle
          disableTypography
          className={classes.modalHeader + " " + localClasses.titleStyle}
          // style={titleStyle}
        >
          <Typography
            variant="h6"
            gutterBottom
            className={classes.modalTitle + " " + localClasses.titleStyle}
            // style={titleStyle}
          >
            Se connecter pour obtenir un accès<br></br> instantané à des
            milliers <br></br>de questions
          </Typography>
        </DialogTitle>
        <DialogActions
          style={{ display: "flex", flexFlow: "column", padding: 4 }}
        >
          <div className={classes.socialLine}>
            <Typography
              gutterBottom
              className={classes.modalTitle + " " + localClasses.conditionStyle}
            >
              En continuant, vous acceptez nos Conditions d'utilisation et vous
              reconnaissez notre Politique de confidentialité.
            </Typography>
            <Button
              className="google gd-btn social-button"
              data-testid="googleBtn"
              onClick={signInWithGoogle}
            >
              <span className="SVGInline">
                <svg
                  className="SVGInline-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 46 46"
                >
                  <path
                    d="M23 8.9a12.75 12.75 0 018.9 3.4L38.37 6A22.1 22.1 0 0023 0 23 23 0 002.45 12.68l7.44 5.78A13.9 13.9 0 0123 8.9z"
                    fill="#ea4335"
                  ></path>
                  <path
                    d="M45.08 23.5a19.7 19.7 0 00-.5-4.7H23v8.55h12.68A11.24 11.24 0 0131 34.8l7.26 5.63c4.34-4 6.85-9.92 6.85-16.92z"
                    fill="#4285f4"
                  ></path>
                  <path
                    d="M9.92 27.55A14.16 14.16 0 019.15 23a14.88 14.88 0 01.74-4.55l-7.45-5.77a23 23 0 000 20.65l7.46-5.78z"
                    fill="#fbbc05"
                  ></path>
                  <path
                    d="M23 46a21.92 21.92 0 0015.23-5.57L31 34.8a13.6 13.6 0 01-8 2.3 13.85 13.85 0 01-13.08-9.55l-7.44 5.77A22.94 22.94 0 0023 46z"
                    fill="#34a853"
                  ></path>
                  <path d="M0 0h46v46H0V0z" fill="none"></path>
                </svg>
              </span>
              <span className="label">Continuer avec Google</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="facebook-login-button"
              onClick={signInWithFacebook}
            >
              <FacebookIcon id="facebook-icon-login" />
              Continuer avec Facebook
            </Button>
          </div>

          <Box
            className="orSeparator"
            display="flex"
            flexGrow={1}
            alignItems="center"
          >
            <Box className="borderTop" width="100%"></Box>

            <div className="orText px-xsm strong">
              <span className="orText">ou</span>
            </div>
            <Box className="borderTop" width="100%"></Box>
          </Box>
          {/* {modalContent} */}
          <SignUpModal />
        </DialogActions>
        <DialogActions>
          <p className="modal-footer-register">
            Pas encore de compte ? S'inscrire
          </p>
        </DialogActions>
      </div>
    </Dialog>
  );
};

LoginModal.defaultProps = {
  classes: {},
};

export default LoginModal;
