import React, { Component } from "react";
import { withStyles, Button } from "@material-ui/core";
import ScrollTo from "react-scroll-into-view";
// we can use react-scroll create a <Link to=#topics> instead   Fab

const warning = {
  color: "#212529",
  // backgroundColor: "#ffc107",
  backgroundColor: "#AD930E",
  padding: ".15em .3em",
  borderRadius: ".25em",
  lineHeight: "1",
};
const title = {
  fontSize: "3.5rem",

  textShadow:
    "rgba(255, 255, 255, 0.4) 2px 3px 6px, 0px 0px 30px rgba(255, 255, 255, 0.075)",
  marginBottom: "1rem",
};

const subTitle = {
  fontSize: "1.5rem",
  lineHeight: "1.8",
};

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
});

const Heading = ({ currentUser }) => {
  return (
    <div
      style={{
        textAlign: "center",
        textShadow: "0 1px 3px rgba(0,0,0,.4), 0 0 30px rgba(0,0,0,.075)",
      }}
    >
      {currentUser && currentUser.isPremium ? (
        <>
          <h1 className="font-montserrat" style={title}>
            Félicitations !
          </h1>{" "}
          <h2 style={{ fontSize: "2rem" }} className="mb-20">
            Vous avez accès à{" "}
            <span style={{ textDecoration: "underline", color: "#F00057" }}>
              toutes les questions
            </span>{" "}
          </h2>
        </>
      ) : (
        <>
          <h1 style={title} className="font-montserrat">
            Envie de faire décoller votre carrière ?
          </h1>
          <h2 style={{ fontSize: "2rem" }} className="mb-20">
            Toutes les questions posées en entretien technique sont sur{" "}
            <ScrollTo selector="#topics">
              <span
                style={{
                  textDecoration: "underline",
                  color: "#F00057",
                  cursor: "pointer",
                }}
              >
                Entretien.tech
              </span>{" "}
            </ScrollTo>
          </h2>
        </>
      )}

      {/* <h4 style={subTitle}>
        <span style={warning}>2150</span> questions Full Stack et ses{" "}
        <span style={warning}>réponses</span>
        <br />
        Un développeur non préparé peut perdre{" "}
        <span style={warning}>-5000 €</span>* au moment de la négociation.
      </h4> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          justifyItems: "center",
        }}
      >
        {/* <ScrollTo selector="#topics">
          <Button
            style={{
              margin: "20px",
              backgroundColor: "#615104",
              color: "#ffffff",
            }}
            // color="primary"
            variant="contained"
          >
            Aller aux questions
          </Button>
        </ScrollTo> */}
      </div>
    </div>
  );
};

export default withStyles(styles)(Heading);
