import React, { useEffect } from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import Heading from "./Heading";
import Topics from "features/topicsAndQuestions/Topics";
import { useLocalStorage } from "app/customHooks";

const Home = ({ currentUser, analyzer }) => {
  const [hasSurvey, setHasSeenSurvey] = useLocalStorage("survey", false);
  const history = useHistory();

  useEffect(() => {
    if (hasSurvey === false) {
      history.push("/survey");
    }
  }, []);
  return (
    <>
      {" "}
      <section
        style={{
          marginTop: "2rem",
          paddingTop: "5rem",
          paddingBottom: "2rem",
          background: "white",
        }}
      >
        <Heading currentUser={currentUser} />
      </section>
      <Container style={{ paddingTop: "5rem", paddingBottom: "5rem" }}>
        <Topics currentUser={currentUser} analyzer={analyzer} />
      </Container>
      <MessengerCustomerChat
        // pageId="502406990682907"
        appId="736289877283677"
        pageId="105199194785607"
        language="fr_FR"
      />
    </>
  );
};

export default Home;
