import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";

const store = configureStore({
    reducer: rootReducer
})

if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./rootReducer", () => {
        const newRootreducer = require('./rootReducer').default;
        store.replaceReducer(newRootreducer);
    })
}

export default store;