import React, { useState, useEffect } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Button, LinearProgress } from "@material-ui/core";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import firebase from "firebase/firebase.utils";
import "./checkoutForm.scss";

const products = new Set(["10days", "lifetime", "hr-lifetime"]);

const createPaymentIntentFun = firebase
  .functions()
  .httpsCallable("createPaymentIntent");

export default function CheckoutForm({ selectedProduct, handleClose, coupon }) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [initIntentOk, setInitIntentOk] = useState(false);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    setLoading(true);
    createPaymentIntentFun({ selectedProduct, coupon })
      .then((res) => {
        setClientSecret(res.data.client_secret);
        setInitIntentOk(true);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setInitIntentOk(false);
        setLoading(false);
      });
  }, [selectedProduct, coupon]);
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      handleClose();
    }
  };
  return (
    <div id="stripe-payment-wrapper">
      {loading && <LinearProgress />}
      {initIntentOk && loading === false ? (
        <form id="payment-form" onSubmit={handleSubmit}>
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
          <button disabled={processing || disabled || succeeded} id="submit">
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay"
              )}
            </span>
          </button>
          {/* Show any error that happens when processing the payment */}
          {error && (
            <div className="card-error" role="alert">
              {error}
            </div>
          )}
          {/* Show a success message upon completion */}
          <p className={succeeded ? "result-message" : "result-message hidden"}>
            Félicitations, votre paiement à réussi.
          </p>
        </form>
      ) : (
        <>
          <Alert severity="info">
            <AlertTitle>Info paiement</AlertTitle>
            Vous devez être connecté afin de pouvoir
          </Alert>
          <Button>Connectez vous</Button>
        </>
      )}
    </div>
  );
}
