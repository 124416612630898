import React from "react";
import "./Footer.style.css";
import customStyle from "./footer.module.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-entretien-tech">
      <p></p>
      <p className={customStyle.copyright}>
        &#9400; 2020-2021 Entretien Tech. Tous droits reservés.
      </p>
      {/* <p>FAQ Vie privée Conditions d'utilisation Contacts</p> */}
      <p>
        <Link
          to="/termes-conditions"
          className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
        >
          Conditions d'utilisation
        </Link>{" "}
        ·
        <Link
          to="/vie-privee"
          className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
        >
          Vie privée
        </Link>{" "}
      </p>
    </footer>
  );
};

export default Footer;
