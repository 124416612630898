import React from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import customStyle from "./problemsPage.module.scss";

const Intro = () => {
  const history = useHistory();
  return (
    <div className={`hero `}>
      <div className="hero--wrapper flex">
        <section className="hero--left-side justify-center align-center">
          <div className="p-20">
            <h1
              className="hero--title h1 font-bold mb-4 font-montserrat"
              style={{ fontSize: "3.25rem" }}
              data-aos="zoom-y-out"
            >
              <span className={customStyle.pink}>NOUVEAU !</span> Rattrapez
              votre retard grâce à des challenges et des concours
              {/* communauté */}
            </h1>
            <p
              className="text-xl text-gray-600"
              data-aos="zoom-y-out"
              data-aos-delay="150"
            >
              Venez rejoindre l'une des plus grandes communautés tech de
              nombreux actifs, participez à des concours pour vous mettre au
              défi et gagner des récompenses.
              <br></br>
              <Button
                style={{
                  marginTop: "20px",
                  backgroundColor: "#615104",
                  color: "#ffffff",
                }}
                onClick={() => history.push("/questions")}
                variant="contained"
              >
                Aller aux questions
              </Button>
              <Button
                style={{
                  marginTop: "20px",
                  marginLeft: "20px",
                  backgroundColor: "#615104",
                  color: "#ffffff",
                }}
                onClick={() => history.push("/")}
                variant="contained"
              >
                Retour à l'accueil
              </Button>
            </p>
          </div>
        </section>
        <div className="hero--right-side">
          <div className="hero--cover w-screen/2" style={{ width: "50vw" }}>
            <div className="hero--image-container">
              <div className="hero--image">
                <div className="hero--image-background"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
