import React, { useState } from "react";
import Intro from "./Intro";
import Fab from "@material-ui/core/Fab";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Unauthorized from "./Unauthorized";

const ProblemsPage = () => {
  const [visible, toggle] = useState(true);
  return (
    <>
      <div style={{ marginTop: "4rem" }} className="relative overflow-hidden">
        <div className="absolute right-2 top-2 z-10">
          <Fab
            color="secondary"
            aria-label="delete"
            onClick={(e) => toggle(!visible)}
          >
            {visible ? <ExpandLess /> : <ExpandMore />}
          </Fab>
        </div>
        {visible && <Intro />}
        {!visible && (
          <p
            className="flex items-center justify-center text-2xl text-gray-600 text-center"
            style={{ height: "76px" }}
          >
            <span>
              Rattrappez votre retard grâce à des challenges et des concours !
            </span>
          </p>
        )}
      </div>
      <Unauthorized />
    </>
  );
};

export default ProblemsPage;
