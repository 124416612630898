import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";

// import { toast } from "react-toastify";

import {
  firestore,
  convertQuestionsTopicsSnapshotToMap,
} from "firebase/firebase.utils";

const managerName = "question";

const initialState = {
  topicsList: [],
  loading: false,
};

// Async treatments
export const getTopicsList = createAsyncThunk(
  `${managerName}/getTopicsList`,
  async (_, { rejectWithValue }) => {
    try {
      const querySnapshot = await firestore
        .collection("questions-topics")
        .get();
      const result = await convertQuestionsTopicsSnapshotToMap(querySnapshot);

      return { topicsList: result };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createQuestionSlice = createSlice({
  name: managerName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTopicsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTopicsList.fulfilled, (state, action) => {
      state.topicsList = action.payload.topicsList;
      state.loading = false;
      // toast.success("Récupération de la liste des topics OK");
    });
  },
});

export default createQuestionSlice.reducer;

// selectors
const managerSelector = (state) => state[managerName];

export const topicsListSelector = createSelector(
  managerSelector,
  (managerState) => managerState.topicsList
);

export const loadingSelector = createSelector(
  managerSelector,
  (managerState) => managerState.loading
);
