import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "app/customHooks";

const Survey = () => {
  const history = useHistory();
  const [hasSurvey, setHasSeenSurvey] = useLocalStorage("survey", true);
  useEffect(() => {
    setHasSeenSurvey(true);
  }, []);

  return (
    <>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeanFkMwg6ZYj2eyOZ4PX3qtrWQ5iWAZe4oh5Z4zLUjQkRzSA/viewform?embedded=true"
        // width="640"
        // height="769"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        style={{ width: "100%", marginTop: "60px", minHeight: "140vh" }}
      >
        Chargement…
      </iframe>
      <br></br>
      <br></br>
      <br></br>
      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        <Button
          style={{
            marginTop: "20px",
            backgroundColor: "#615104",
            color: "#ffffff",
          }}
          onClick={() => history.push("/")}
          variant="contained"
        >
          Aller aux questions
        </Button>
      </div>
    </>
  );
};

export default Survey;
