import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CardStudent from "./CardStudent";
import SchoolIcon from "@material-ui/icons/School";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import Redeem from "@material-ui/icons/Redeem";
import LiveHelp from "@material-ui/icons/LiveHelp";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

function SectionTopics() {
  const history = useHistory();
  const handleClick = (e) => history.push("/");

  const footer = (
    <p style={{ textAlign: "right" }}>
      <ArrowForwardIcon onClick={handleClick} />
    </p>
  );

  return (
    <section className="bg-gray-50">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h1
              className="h1 mb-4 text-3xl	font-bold font-montserrat"
              data-aos="zoom-y-out"
            >
              Choisissez les questions et les thèmes que vous voulez étudier
              aujourd'hui
            </h1>
            <p
              className="text-xl text-gray-600"
              data-aos="zoom-y-out"
              data-aos-delay="150"
            >
              Plus de 100 topics dont Java, React, HTML, DotNet mais d'autres
              sont rajoutés régulièrement.
            </p>
          </div>

          <div className="container of studen tables">
            <div className="max-w-sm md:max-w-2xl xl:max-w-none mx-auto grid gap-8 md:grid-cols-2 xl:grid-cols-4 xl:gap-6 items-start">
              <CardStudent
                number="Java "
                icon={LiveHelp}
                subtitle="147 questions"
                description="Swagger, OOP,..."
                onClick={handleClick}
                footer={footer}
                hasAvatar={false}
              />
              <CardStudent
                number="Javascript"
                icon={LiveHelp}
                subtitle="142 questions"
                description="ES6, ES7..."
                onClick={handleClick}
                footer={footer}
              />
              <CardStudent
                number="React"
                icon={LiveHelp}
                subtitle="164 questions"
                description="Jsx, useEffect,..."
                onClick={handleClick}
                footer={footer}
              />
              <CardStudent
                number="PHP"
                icon={LiveHelp}
                subtitle="PHP"
                description="PDO, stdClass, PSR, isset,..."
                onClick={handleClick}
                footer={footer}
              />
              <CardStudent
                number="C#"
                icon={LiveHelp}
                subtitle="C#"
                description="Abstracts, generics, reflection,..."
                onClick={handleClick}
                footer={footer}
              />
              <CardStudent
                number="HTML"
                icon={LiveHelp}
                subtitle="80 questions"
                description="Les tags différents tags...."
                onClick={handleClick}
                footer={footer}
              />
              <CardStudent
                number="Android"
                icon={LiveHelp}
                subtitle="Android"
                description="Bitmap, AsyncTask,ArmV7..."
                onClick={handleClick}
                footer={footer}
              />
              <CardStudent
                number="Sql"
                icon={LiveHelp}
                subtitle="56 questions"
                description="Where, Truncate, Inner Join, Group, Minus, Intersect,...."
                onClick={handleClick}
                footer={footer}
              />
              <CardStudent
                number="Python"
                icon={LiveHelp}
                subtitle="95 questions"
                description="oop, Python path, memory management, python modules, ternary operators,...."
                onClick={handleClick}
                footer={footer}
              />
              <CardStudent
                number="Angular"
                icon={LiveHelp}
                subtitle="120 questions"
                description="directives, , INNER JOIN, GROUP, MINUS, INTERSECT,...."
                onClick={handleClick}
                footer={footer}
              />
              <CardStudent
                number="Devops"
                icon={LiveHelp}
                subtitle="39 questions"
                description="directives, , INNER JOIN, GROUP, MINUS, INTERSECT,...."
                onClick={handleClick}
                footer={footer}
              />
              <CardStudent
                number="Spring"
                icon={LiveHelp}
                subtitle="87 questions"
                description="directives, , INNER JOIN, GROUP, MINUS, INTERSECT,...."
                onClick={handleClick}
                footer={footer}
              />
            </div>
          </div>

          <div className="max-w-3xl mx-auto text-center pb-12 mt-20">
            <h1 className="h1 mb-4 text-3xl	font-bold" data-aos="zoom-y-out">
              Et encore bien d'autres...
            </h1>
            <p
              className="text-xl text-gray-600"
              data-aos="zoom-y-out"
              data-aos-delay="150"
            >
              Et encore bien d'autres topic.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionTopics;
