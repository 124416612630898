import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

var config = {
  apiKey: "AIzaSyAvMtbM46GZ6kAfUy6JCY0LpJreh_iH0S4",
  authDomain: "prod-entretien-tech.firebaseapp.com",
  databaseURL: "https://prod-entretien-tech.firebaseio.com",
  projectId: "prod-entretien-tech",
  storageBucket: "prod-entretien-tech.appspot.com",
  messagingSenderId: "160706187404",
  appId: "1:160706187404:web:a1e7bbf8472ec4940baba9",
  measurementId: "G-D2Z4TE2HZJ",
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;
  const userRef = firestore.doc(`/users/${userAuth.uid}`);

  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const { displayName, email, photoURL } = userAuth;
    const createAt = Date.now();

    try {
      await userRef.set({
        displayName,
        email,
        createAt,
        photoURL,
        ...additionalData,
      });
    } catch (err) {
      console.log("failed creation", err.message);
    }
  }

  return userRef;
};

export const convertQuestionsSnapshotToMap = (questions) => {
  const transformedQuestion = questions.docs.map((doc) => {
    const {
      title,
      answer,
      questionDetailId,
      score,
      isCodeChallenge,
    } = doc.data();

    return {
      title,
      answer,
      questionId: doc.id,
      questionDetailId,
      score,
      isCodeChallenge,
    };
  });
  return transformedQuestion;
};

export const convertQuestionsTopicsSnapshotToMap = (questionsTopics) => {
  const transformedQuestionTopics = questionsTopics.docs.map((doc) => {
    const { topic, name, qtyTodo, qtyDone, categories } = doc.data();
    return {
      topic,
      id: doc.id,
      name,
      qtyTodo,
      qtyDone,
      categories,
    };
  });

  return transformedQuestionTopics;
};

// Initialize Firebase
firebase.initializeApp(config);
// firebase.analytics();

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);
const fbProvider = new firebase.auth.FacebookAuthProvider();
export const signInWithFacebook = () => auth.signInWithPopup(fbProvider);

export default firebase;
