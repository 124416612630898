import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import CodeBlockTemplate from "components/markdownViewer/CodeBlock";
import { Typography, Grid, Box } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import "./question-item.css";
import "./question-item.scss";
import { firestore } from "firebase/firebase.utils";
import Rating from "@material-ui/lab/Rating";

const CodeBlock = (props) => (
  <CodeBlockTemplate {...props} language="javascript" />
);

const QuestionDetailFromChildId = ({ childId }) => {
  const [questionDetail, setQuestionDetail] = useState(null);
  useEffect(() => {
    let path = `questions-details/${childId}`;
    const questionDetailRef = firestore.doc(path);

    questionDetailRef.onSnapshot((snap) => {
      setQuestionDetail({ ...snap.data(), id: snap.id });
    });
  }, [childId]);
  return (
    questionDetail && (
      <Grid>
        <ReactMarkdown
          source={questionDetail.content}
          key={questionDetail.id}
          renderers={{ code: CodeBlock, language: "js" }}
        />
      </Grid>
    )
  );
};

const QuestionItem = ({
  title,
  questionId,
  childId,
  index,
  scoreDifficulty,
  currentUser,
  analyzer,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleHeaderQuestionClick = (e) => {
    analyzer &&
      typeof analyzer.pageview === "function" &&
      analyzer.pageview(`QUESTIONTITLE:${title}`);

    // analyzer &&
    //   typeof analyzer.gtag === "function" &&
    //   analyzer.gtag("event", "question_item", `QUESTIONTITLE:${title}`);

    setExpanded(!expanded);
  };

  const difficultyLabelMap = {
    0: "débutant",
    1: "junior",
    2: "expérimenté",
    3: "senior",
    4: "expert",
  };

  const classNameDifficultyMap = {
    0: "difficulty-0",
    1: "difficulty-1",
    2: "difficulty-2",
    3: "difficulty-3",
    4: "difficulty-4",
  };

  const difficultyLabel = difficultyLabelMap[Math.floor(scoreDifficulty / 10)];
  const classNameDifficulty =
    classNameDifficultyMap[Math.floor(scoreDifficulty / 10)];

  return (
    <div>
      <Grid
        container
        spacing={0}
        direction={"row"}
        justify={"space-between"}
        className="question-header"
        onClick={handleHeaderQuestionClick}
      >
        <Typography variant="h6">
          <span
            className="question-number"
            style={{
              fontStyle: "italic",
              color: "#6c757d",
              fontWeight: "bolder",
              marginRight: "1rem",
            }}
          >
            Q{index + 1}:
          </span>
          {title}
        </Typography>
        <Box className="question-difficulty">
          {!(currentUser && currentUser.isPremium) && scoreDifficulty >= 10 && (
            <LockIcon />
          )}
          <span className={`badge ${classNameDifficulty}`}>
            {difficultyLabel}
          </span>
        </Box>
      </Grid>
      {expanded && (
        <div className="question-detail">
          {questionId && <QuestionDetailFromChildId childId={childId} />}
        </div>
      )}
    </div>
  );
};

export default QuestionItem;
