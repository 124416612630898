import React, { useState } from "react";
// import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  RadioGroup,
  Radio,
  FormControlLabel,
  Avatar,
  Typography,
  DialogContent,
  withStyles,
} from "@material-ui/core";
import {
  Lock as LockIcon,
  CheckBox,
  DoneOutline as DoneOutlineIcon,
} from "@material-ui/icons";
import CheckoutForm from "../checkout/CheckoutForm";
import PriceLabel from "./PriceLabel";

import "./unlock.css";

const unlockHeader = {
  width: "100%",
  backgroundImage: 'url("assets/family.jpg")',
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
};

// const DialogContent = withStyles((theme) => ({
//   root: {
//     // padding: "16px",
//   },
// }))(MuiDialogContent);

const golden = {
  backgroundColor: "#bbabaa",
  color: "rgb(33, 37, 41)",
  padding: "0.15em 0.3em",
  borderRadius: "0.2em",
};

const couponsList = [
  {
    name: "BIENVENUE",
    pourcent: 30,
  },
];

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);
  // const [selectedProduct, setSelectedProduct] = React.useState("10days");
  // const [selectedProduct, setSelectedProduct] = React.useState("hr-lifetime");
  const [selectedProduct, setSelectedProduct] = React.useState("lifetime");
  const [coupon, setCoupon] = useState("");

  //  if coupon is valid coupon apply
  const [validCoupon, setValidCoupon] = useState({});
  const onCouponChange = (e) => {
    const value = e.target.value;
    setCoupon(value);
    const index = couponsList.findIndex((el) => el.name === value);
    if (index > -1) {
      setValidCoupon(couponsList[index]);
    } else {
      setValidCoupon({});
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  return (
    <div>
      <Button
        style={{
          textTransform: "capitalize",
          marginRight: "1rem",
          backgroundColor: "#615104",
          color: "#fff",
        }}
        variant="contained"
        onClick={handleClickOpen}
      >
        <LockIcon className="unlock-lockicon" />
        débloquez 2050 réponses
      </Button>

      <Dialog
        maxWidth={false}
        style={{ maxHeight: "none" }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div style={unlockHeader}>
          <div
            style={{
              textAlign: "center",
              background: "rgba(0, 0, 0, 0.35)",
              padding: "2rem",
              lineHeight: "2",
              color: "white",
              textShadow: "0 1px 3px rgba(0,0,0,.4), 0 0 30px rgba(0,0,0,.075)",
            }}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: "700", lineHeight: "2" }}
            >
              Débloquez <span style={golden}>2050</span> questions !
              <br />
            </Typography>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Obtenez l'<span style={golden}>emploi</span> que vous méritez !
            </Typography>
          </div>
        </div>
        <DialogContent dividers>
          <Typography
            style={{
              fontWeight: "700",
              textAlign: "center",
              textShadow: "0 1px 3px rgba(0,0,0,.4), 0 0 30px rgba(0,0,0,.075)",
            }}
          >
            <DoneOutlineIcon style={{ marginRight: "1rem", size: "1rem" }} />
            Accès illimité à <span style={golden}>2050</span> questions
            d'entretien
            <br />
            <DoneOutlineIcon style={{ marginRight: "1rem" }} />
            Libérez le <span style={golden}>temps</span> passé à chercher
          </Typography>
        </DialogContent>
        <DialogContent>
          <RadioGroup
            style={{
              flexDirection: "row",
              justifyContent: "center",
              margin: "auto 2rem",
            }}
            aria-label="offer"
            name="offer"
            value={selectedProduct}
            onChange={handleChange}
          >
            <FormControlLabel
              value="10days"
              control={<Radio />}
              label={
                <PriceLabel
                  selected={selectedProduct}
                  title="Expérimenté"
                  duration="10 jours!"
                  price="35.00"
                  product="10days"
                  moreClasses="price-normal-size"
                  validCoupon={validCoupon}
                />
              }
            />
            <FormControlLabel
              value="lifetime"
              control={<Radio />}
              label={
                <PriceLabel
                  selected={selectedProduct}
                  title="Objectif senior"
                  // duration="Accès illimité"
                  duration="Illimité pendant 1 an !"
                  price="71.00"
                  product="lifetime"
                  moreClasses="price-big-size"
                  validCoupon={validCoupon}
                />
              }
            />
            <FormControlLabel
              value="hr-lifetime"
              control={<Radio />}
              label={
                <PriceLabel
                  selected={selectedProduct}
                  title="Lead et recrutement"
                  duration="Illimité à vie"
                  price="128.99"
                  product="hr-lifetime"
                  moreClasses="price-normal-size"
                  validCoupon={validCoupon}
                />
              }
            />
          </RadioGroup>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{ fontSize: "0.875rem", margin: "0.5rem", color: "#777" }}
            >
              <CheckBox
                style={{ transform: "translateY(6px)", color: "#f50057" }}
              />{" "}
              {/* Paiement unique • Approuvé sous 24h-48h • Garanti 100% */}
              Paiement instantané • Garanti 100% • +337 utilisateurs
              <span className="coupon">
                <input
                  onChange={onCouponChange}
                  type="text"
                  placeholder="CODE"
                  name="promo"
                  value={coupon}
                ></input>
              </span>
            </Typography>
            <div style={{ marginBottom: "15px" }}>
              <CheckoutForm
                handleClose={handleClose}
                selectedProduct={selectedProduct}
                coupon={coupon}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              padding: "auto",
              flexDirection: "column",
            }}
          >
            <i style={{ fontSize: "10px", width: "20rem" }}>
              ―"Entretien.tech m'a donné une vue globale et rapide, ce qui était
              communément demandé, de revoir ou voir ce que je ne savais pas. Je
              n'aurais pas pu atteindre mon objectif de salaire sans votre
              aide."
            </i>
            <Avatar src="assets/female2016102479812116.jpg" />
            <span role="img" aria-label="rating">
              ⭐⭐⭐⭐⭐
            </span>
            <i
              style={{
                textAlign: "center",
                fontSize: "10px",
                width: "80%",
                minWidth: "25rem",
              }}
            >
              L. K. Développeur à Sourire.
            </i>
            <Typography
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: "auto",
                flexDirection: "column",
              }}
            ></Typography>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
