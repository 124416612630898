import React from "react";

const TermsConditions = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      <div className="pt-32 pb-12 md:pt-40 md:pb-20">
        {/* Page header */}
        <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
          <h1 className="h1 mb-4 text-3xl">Conditions d'utilisation</h1>
          <ol>
            <li>
              <h2 className="text-2xl">1.Conditions d'utilisation</h2>
              <p className="mt-2 text-lg text-gray-600">
                Les présentes conditions générales définissent les règles et
                règlements du site web, situé à l'adresse
                https://entretien.tech. En accédant à ce site Web, nous
                supposons que vous acceptez ces conditions. Ne continuez pas à
                utiliser entretien.tech si vous n'acceptez les conditions
                énoncées ici.
                <br></br> La terminologie suivante s'applique aux présentes
                conditions générales, à la déclaration de confidentialité et à
                l'avis de non-responsabilité ainsi qu'à tous les accords :
                "Client", "Vous" et "Votre" font référence à vous, la personne
                qui se connecte à ce site web et qui se conforme aux termes et
                conditions de la Société.
                <br></br> "La Société", "Nous", "Notre", "Nos" et "Nous",
                désigne notre Société. "Partie", "Parties", ou "Nous", désigne à
                la fois le client et nous-mêmes. Tous les termes font référence
                à l'offre, à l'acceptation et à la prise en compte du paiement
                nécessaire pour entreprendre le processus de notre assistance au
                Client de la manière la plus appropriée dans le but exprès de
                répondre aux besoins du Client en ce qui concerne la fourniture
                des services déclarés de la Société, conformément et sous
                réserve de la loi en vigueur aux Pays-Bas. Toute utilisation de
                la terminologie ci-dessus ou d'autres mots au singulier, au
                pluriel, en majuscules et/ou il/elle ou ils/elles, sont
                considérés comme interchangeables et donc comme se référant au
                même.
              </p>
            </li>
            <li>
              <h2 className="text-2xl">2.Avis de non-responsabilité</h2>
              <p className="mt-2 text-lg text-gray-600"></p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
