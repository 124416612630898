import React from "react";
import { Avatar } from "@material-ui/core";
import SchoolIcon from "@material-ui/icons/School";
import "./cardStudent.scss";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  icon: {
    fontSize: "35px",
    color: "rgb(33, 150, 243)",
  },
  avatar: {
    borderRadius: "16px",
    background: "#e3f2fd",
  },
  number: {
    color: "#3f51b5",
  },
});

const CardStudent = ({ icon, ...props }) => {
  const customStyle = useStyles();
  const Icon = icon;
  return (
    <div
      className="student-card relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl"
      data-aos="zoom-y-out"
      data-aos-delay="450"
      onClick={props.onClick}
    >
      <div className="mb-4">
        {props.hasAvatar && (
          <Avatar
            variant="rounded"
            className={`student-avatar mb-3 ${customStyle.avatar}`}
            style={{ height: "70px", width: "70px" }}
          >
            <Icon className={customStyle.icon} />
          </Avatar>
        )}
        <h4
          className={`text-3xl font-bold leading-snug tracking-tight mb-2 ${customStyle.number}`}
        >
          {props.number}
        </h4>
        <div className="text-lg font-bold mb-2 font-montserrat">
          {props.subtitle}
        </div>
        <div className="text-base text-gray-800">{props.description}</div>
        {props.footer}
      </div>
    </div>
  );
};

export default CardStudent;
