import React from "react";
import { useHistory } from "react-router-dom";
import SectionStudents from "./SectionStudents";
import SectionTopics from "./SectionTopics";
import Button from "@material-ui/core/Button";
import SectionTestimonials from "./SectionTestimonials";
import "./landing.scss";

const LandingPage = () => {
  const history = useHistory();
  return (
    <div style={{ marginTop: "4rem" }} id="landing-page">
      <div className="hero">
        <div className="hero--wrapper flex">
          <section className="hero--left-side justify-center align-center">
            <div className="p-20">
              <h1
                className="hero--title h1 font-bold mb-4 font-montserrat"
                style={{ fontSize: "3.25rem" }}
                data-aos="zoom-y-out"
              >
                {/* Toutes les questions posées en entretien technique sont ici */}
                Le moyen le plus rapide/simple de se préparer à un entretien
                technique
              </h1>
              <p
                className="text-xl text-gray-600"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                Donnez libre cours à votre ambition. Développez les compétences
                de demain pour atteindre vos objectifs professionnels.<br></br>
                <Button
                  style={{
                    marginTop: "20px",
                    backgroundColor: "#615104",
                    color: "#ffffff",
                  }}
                  onClick={() => history.push("/questions")}
                  variant="contained"
                >
                  Aller aux questions
                </Button>
                <Button
                  style={{
                    marginTop: "20px",
                    marginLeft: "20px",
                    backgroundColor: "#615104",
                    color: "#ffffff",
                  }}
                  onClick={() => history.push("/problems")}
                  variant="contained"
                >
                  Aller aux algorithmes
                </Button>
              </p>
            </div>
          </section>
          <div className="hero--right-side">
            <div className="hero--cover w-screen/2" style={{ width: "50vw" }}>
              <div className="hero--image-container">
                <div className="hero--image">
                  <div className="hero--image-background"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="divider-landing" />
      <SectionStudents />
      <SectionTopics />
      <SectionTestimonials />
    </div>
  );
};

export default LandingPage;
