import React, { useState, useEffect } from "react";
import { Card, CardContent } from "@material-ui/core";
import QuestionItem from "components/questionItem/QuestionItem";

import {
  firestore,
  convertQuestionsSnapshotToMap,
} from "firebase/firebase.utils";

// container
// List of questions
const QuestionsWithSelectedTopic = (props) => {
  const { selectedTopic, currentUser, analyzer } = props;
  const [questionsByTopic, setQuestionsByTopic] = useState([]);
  useEffect(() => {
    let unsubscribeQuestionsByTopicRef;
    if (Object.keys(selectedTopic).length > 0) {
      const QuestionsByTopicRef = firestore
        .collection(`questions-topics/${selectedTopic.topic}/questions`)
        .orderBy("score", "asc");
      unsubscribeQuestionsByTopicRef = QuestionsByTopicRef.onSnapshot(
        async (snapshot) => {
          const convertedQuestionTopics = await convertQuestionsSnapshotToMap(
            snapshot
          );
          setQuestionsByTopic(convertedQuestionTopics);
        }
      );
    }

    analyzer &&
      typeof analyzer.pageview === "function" &&
      analyzer.pageview(`TOPIC SELECTIONNE:${selectedTopic.topic}`);

    return () => {
      if (unsubscribeQuestionsByTopicRef) {
        unsubscribeQuestionsByTopicRef();
      }
    };
  }, [selectedTopic]);

  const codeChallenges = questionsByTopic.filter((e) => e.isCodeChallenge);
  const noCodeChallenges = questionsByTopic.filter((e) => !e.isCodeChallenge);

  if (questionsByTopic) {
    return (
      <Card>
        <CardContent>
          {questionsByTopic &&
            noCodeChallenges.map((question, index) => (
              <QuestionItem
                index={index}
                title={question.title}
                answer={question.answer}
                questionId={question.questionId}
                childId={question.questionDetailId}
                scoreDifficulty={question.score}
                key={question.questionId}
                topic={selectedTopic}
                currentUser={currentUser}
                analyzer={analyzer}
              />
            ))}
          <hr></hr>
          <h3>Code challenges</h3>
          {codeChallenges &&
            codeChallenges.map((question, index) => (
              <QuestionItem
                index={index}
                title={question.title}
                answer={question.answer}
                questionId={question.questionId}
                childId={question.questionDetailId}
                scoreDifficulty={question.score}
                key={question.questionId}
                topic={selectedTopic}
                currentUser={currentUser}
                analyzer={analyzer}
              />
            ))}
        </CardContent>
      </Card>
    );
  }
  return null;
};

export default QuestionsWithSelectedTopic;
