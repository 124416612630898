import React, { Component } from "react";
// import ReactGA from "react-ga";
import GA4React from "ga-4-react";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "css/style.scss";

import Home from "features/home/Home";
import Navbar from "app/layout/navbar/Navbar";
import Footer from "app/layout/footer/Footer";
import Landing from "features/landing";
import Survey from "features/survey";
import ProblemsPage from "features/problems/problemsPage/ProblemsPage";
import TermsConditions from "features/termsConditions";

import { auth, createUserProfileDocument } from "firebase/firebase.utils";

const style = {
  background: "#F6F7F6",
  // background: "linear-gradient(to right, #24c6dc, #514a9d);",
};

const promise = loadStripe(
  // "pk_test_51Ho5neLidFMegNuZBNJhkVnkuI5CY93SvHd4TkYEwfPa1tc77Nr6CLuddQcgi9HWh4M6GBZ6VwDgajSjZaWDU6yN00YmZ1YOA7"
  "pk_live_51Ho5neLidFMegNuZlQfYtKkIg8vF7rLoLQXMib17RkCN5P7eUCdxw4upf4rByh7ZgGQHPXmvgBhCsVom49h6azzr00r8uideay"
);

const trackingId = "G-E5LPGL4LEE";

const ga4react = new GA4React(trackingId, {}, [], 5000);

export class App extends Component {
  constructor() {
    super();

    this.state = {
      currentUser: null,
    };
  }

  unsubscribeFromAuth;

  analyzer;

  componentDidMount() {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);

        userRef.onSnapshot((snapshot) => {
          this.setState({
            currentUser: {
              id: snapshot.id,
              ...snapshot.data(),
            },
          });
        });
      }
    });
    // const trackingId = "G-D2Z4TE2HZJ";
    // const trackingId = "G-E5LPGL4LEE";

    // ReactGA.initialize(trackingId);
    ga4react.initialize().then(
      (ga4) => {
        ga4.pageview("/");
        this.analyzer = ga4;
        // ga4.gtag('event','pageview','path') // or your custom gtag event
      },
      (err) => {
        console.error(err);
      }
    );

    // ReactGA.pageview(window.location.pathname + window.location.search);
    // ReactGA.pageview("/");
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
    this.analyzer = null;
  }

  render() {
    return (
      <div className="App" style={style}>
        <ToastContainer hideProgressBar />
        <Elements stripe={promise}>
          <header className="App-header">
            <Navbar currentUser={this.state.currentUser} fixed />
          </header>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route
              exact
              path="/questions"
              component={(props) => (
                <Home
                  {...props}
                  toto="toto"
                  currentUser={this.state.currentUser}
                  analyzer={this.analyzer}
                />
              )}
            />
            <Route exact path="/survey" component={Survey} />
            <Route exact path="/problems" component={ProblemsPage} />
            <Route
              exact
              path="/termes-conditions"
              component={TermsConditions}
            />
          </Switch>
        </Elements>
        <Footer />
      </div>
    );
  }
}

export default App;

// this.setState({ currentUser: user });

// console.log(user);

// pour créer une uqesiotn on a title etc detail,  on va enregistrer le detail  avec l'id on va enregistrer la question on va set questionDetailId
